import { Modal, Button } from "antd";
import { useEffect, useState } from "react";

const CustomModal = ({title,content,show,setShow}) => {
  const [visible, setVisible] = useState(show);
  
  const handleClose = () =>{
    setShow(false);
    setVisible((prev) => !prev);
  }

  useEffect(()=>{
    setVisible(show)
  },[show])

  return (

      <Modal
        title={title}
        visible={visible}
        onOk={handleClose}
        onCancel={handleClose}
        
      >
        <div
        style={{overflowX:'auto'}}
        >
        {content?
        content
        :<p>Some contents...</p>}
        </div>
      </Modal>
  );
};

export default CustomModal;